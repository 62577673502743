<template>
	<div class="student_page">
		<Banner :adCategory="5"></Banner>
		<div class="module1">
			<div class="content">
				<CourseTitle
					title="优秀学员"
					contText="因为你真正的耕耘，才有无穷的收获"
				>
				</CourseTitle>
				<div class="swiper_box">
					<swiper class="swiper" ref="swiper" :options="swiperOption">
						<swiper-slide
							v-for="(item, index) in formData.studentVOS"
							:key="index"
						>
							<div
								class="list"
								@mouseover="mouseOver(index)"
								@mouseleave="mouseLeave"
							>
								<img class="swiper_img" :src="item.studentAvatar" alt="" />

								<div
									class="introduction_box flex-center column"
									v-if="studentIndex == index"
								>
									<div class="lecturer_name">{{ item.typeWorkName }}</div>
									<div class="line"></div>
									<div class="instructor_experience">
										培训日期：{{ item.trainingDate }}
									</div>
									<div class="instructor_experience display flex-wrap">
										作品：
										<span
											class="productionName"
											v-for="(items, indexs) in item.worksName"
											:key="indexs"
										>
											{{ items }} <span class="symbol">、</span>
										</span>
									</div>
								</div>
								<div class="cont display column">
									<div class="name">{{ item.studentName }}</div>
									<div class="workingExperience">
										培训日期：{{ item.trainingDate }}
									</div>
								</div>
							</div>
						</swiper-slide>

						<div class="swiper-button-prev" slot="button-prev">
							<img
								class="icon_left_right"
								src="@/assets/img/home/left1.png"
								alt=""
							/>
						</div>
						<div class="swiper-button-next" slot="button-next">
							<img
								class="icon_left_right"
								src="@/assets/img/home/right1.png"
								alt=""
							/>
						</div>
					</swiper>
				</div>
			</div>
		</div>
		<div class="module2">
			<div class="content module_box">
				<CourseTitle title="学员作品" contText="一线施工现场，见证从0到1的蜕变">
				</CourseTitle>
				<div class="carousel_box">
					<el-carousel :interval="5000" arrow="always">
						<el-carousel-item
							v-for="(item, index) in formData.studentWorksVOS"
							:key="index"
						>
							<div class="el_carousel_cont flex">
								<div class="el_carousel_cont_fl">
									<img :src="item.coverUrl" alt="" />
								</div>
								<div class="el_carousel_cont_fr">
									<div class="title">{{ item.worksName }}</div>
									<div class="list flex">
										<div class="label">项目学员：</div>
										<div
											class="details studentName"
											v-for="(items, indexs) in item.studentNames"
											:key="indexs"
										>
											{{ items }} <span>,</span>
										</div>
									</div>
									<div class="list flex">
										<div class="label">参与项目：</div>
										<div class="details">{{ item.projectName }}</div>
									</div>
									<div class="list flex">
										<div class="label">工程量：</div>
										<div class="details">{{ item.projectArea }}㎡</div>
									</div>
									<div class="list flex">
										<div class="label">施工部位：</div>
										<div class="details">{{ item.constructionSite }}</div>
									</div>
									<div class="list flex">
										<div class="label">项目地址：</div>
										<div class="details">{{ item.province }}</div>
									</div>
									<div class="construction_img flex">
										<img
											v-for="(items, index) in item.worksImageUrls"
											:key="index"
											:src="items"
											alt=""
										/>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="module3">
			<div class="content">
				<CourseTitle title="学员采访" contText="不负时光，收获精彩人生!">
				</CourseTitle>
				<div class="carousel_box">
					<el-carousel :interval="5000" arrow="always">
						<el-carousel-item>
							<div class="el_carousel_cont flex flex-wrap">
								<div
									class="list pointer"
									v-for="(item, index) in formData.studentInterviewVOS"
									:key="index"
									@click="learning(item)"
								>
									<div class="list_img">
										<div class="playBtn display">
											<img src="@/assets/img/student/play.png" alt="" />
										</div>
										<video class="icon_bg" :src="item.videoUrl"></video>
										<!-- <video class="icon_bg" src="@/assets/img/student/1.png" alt=""> -->
									</div>
									<div class="cont">
										<div class="college_name">{{ item.studentName }}</div>
										<div class="company_name">
											就职于{{ item.employmentCompany }}
										</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="video_mask_layer" v-if="videoShow == true">
			<div class="mask_layer"></div>
			<div class="video_cont">
				<img
					class="offBtn"
					@click="videoShow = false"
					src="@/assets/img/home/off.png"
					alt=""
				/>
				<video class="videoCont" :src="videoUrl" width="100%" controls></video>
			</div>
		</div>
	</div>
</template>
<script>
import Banner from '@/components/banner'
import CourseTitle from './components/courseTitle.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	components: {
		Banner,
		Swiper,
		SwiperSlide,
		CourseTitle,
	},
	data() {
		return {
			videoShow: false,
			videoUrl: '',
			studentIndex: null,
			imgIndex: '',
			studentList: [{}, {}, {}, {}],
			worksList: [{}, {}, {}, {}],
			interviewList: [{}, {}, {}, {}, {}, {}, {}, {}],
			swiperOption: {
				slidesPerView: 4,
				spaceBetween: 26,
				direction: 'horizontal',
				observer: true,
				observeParents: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					resize: () => {
						this.$refs.swiper.$swiper.changeDirection(
							window.innerWidth <= 400 ? 'vertical' : 'horizontal'
						)
					},
				},
			},
			formData: {},
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData() {
			this.api.findStudentInfo().then((res) => {
				this.formData = res.data || []
			})
		},
		mouseOver(index) {
			this.studentIndex = index
		},
		mouseLeave() {
			this.studentIndex = null
		},
		learning(item) {
			this.videoShow = true
			this.videoUrl = item.videoUrl
		},
	},
}
</script>
<style lang="scss" scoped>
.student_page {
	.module1 {
		.swiper_box {
			margin-top: 63px;

			::v-deep.swiper-container {
				height: 420px;
			}
			::v-deep.swiper-slide {
				padding-top: 5px;
			}
			::v-deep.swiper-wrapper {
				padding-left: 6px;
			}
			.icon_left_right {
				width: 48px;
				height: 48px;
			}

			.list {
				width: 270px;
				height: 385px;
				box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
				padding: 4px 5px;
				box-sizing: border-box;
				position: relative;
				.productionName:last-child {
					.symbol {
						display: none;
					}
				}
				.swiper_img {
					width: 100%;
					height: 260px;
				}

				.cont {
					width: 280px;
					height: 126px;

					.name {
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333 !important;
						line-height: 22px;
					}

					.workingExperience {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666 !important;
						line-height: 24px;
						margin-top: 19px;
					}
				}

				.introduction_box {
					width: 280px;
					height: 390px;
					background: #000;
					opacity: 0.8;
					position: absolute;
					top: 0;
					left: 0;
					padding-top: 92px;
					box-sizing: border-box;

					.lecturer_name {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #fff;
						line-height: 24px;
					}

					.line {
						width: 44px;
						height: 2px;
						background: #ff881e;
						margin-top: 14px;
					}

					.instructor_experience {
						width: 211px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #fffefe;
						line-height: 24px;
						margin-top: 10px;
						text-align: center;
					}
				}
			}
		}
	}
	.module2 {
		height: 878px;
		background: url('../../assets/img/student/bg1.png') no-repeat;
		background-size: 100% 100%;
		.module_box {
			width: 1400px;
			.carousel_box {
				margin-top: 53px;
			}

			::v-deep.el-carousel__container {
				height: 486px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			::v-deep.el-carousel {
				.el-carousel__item {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.el-carousel__container {
					.el-carousel__arrow {
						width: 48px;
						height: 48px;
						font-size: 24px;
						background: rgba(51, 51, 51, 0.5);
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.el_carousel_cont {
				width: 1208px;
				height: 486px;
				background: #ffffff;
				border-radius: 20px;
				padding: 26px 24px;
				box-sizing: border-box;

				.el_carousel_cont_fl {
					img {
						width: 640px;
						height: 430px;
						margin-right: 30px;
					}
				}

				.el_carousel_cont_fr {
					.title {
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 20px;
						margin-top: 20px;
						margin-bottom: 27px;
					}

					.list {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						line-height: 14px;
						margin-bottom: 17px;
						.label {
						}

						.studentName:last-child {
							span {
								display: none;
							}
						}
					}
					.construction_img {
						margin-top: 47px;
						img {
							width: 222px;
							height: 177px;
							margin-right: 17px;
						}
					}
				}
			}
		}
	}
	.module3 {
		height: 970px;
		.carousel_box {
			width: 1214px;

			margin-top: 51px;
			::v-deep.el-carousel__container {
				height: 650px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			::v-deep.el_carousel_cont {
				padding-top: 5px;
				padding-left: 6px;
				box-sizing: border-box;
			}
			.list:nth-child(4n + 1) {
				margin-left: 0 !important;
			}
			.list {
				width: 280px;
				height: 280px;
				background: #ffffff;
				box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
				margin-left: 28px;
				margin-bottom: 32px;
				.list_img {
					position: relative;
					.icon_bg {
						width: 280px;
						height: 200px;
					}
					.playBtn {
						width: 280px;
						height: 200px;
						position: absolute;
						img {
							width: 50px;
							height: 50px;
						}
					}
				}

				.cont {
					width: 100%;
					height: 80px;
					padding: 17px;
					box-sizing: border-box;

					.college_name {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.company_name {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin-top: 13px;
						line-height: 14px;
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.swiper-button-prev:after,
.swiper-button-next:after {
	content: none;
}
.video_mask_layer {
	.video_cont {
		width: 900px;
		height: 550px;
		background: white;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -275px;
		margin-left: -450px;
		z-index: 10;
		padding: 20px;
		box-sizing: border-box;

		.videoCont {
			width: 100%;
			height: 100%;
		}

		.offBtn {
			width: 20px;
			height: 20px;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}
</style>